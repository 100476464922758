<template>
  <div class="suitInfo">
    <div class="title">
      套装优惠详情
      <span style="margin-right: 20px; font-size: 12px">
        <span v-if="infoData.status_txt == '接下来的活动'" class="tag"
          >接下来的活动</span
        >
        <span v-if="infoData.status_txt == '进行中的活动'" class="tag1"
          >进行中的活动</span
        >
        <span v-if="infoData.status_txt == '已过期'" class="tag2">已过期</span>
      </span>
    </div>
    <div class="cell1">
      <div class="titles">基本信息</div>
      <div class="info">
        <div>
          套装类型：
          <span style="color: #000">{{ infoData.suit_type_txt }}</span>
        </div>
        <div>
          套装名称：
          <span style="color: #000">{{ infoData.suit_name }}</span>
        </div>
        <div>
          套装周期
          <span style="color: #000"
            >{{ infoData.start_time | formatTime }}-{{
              infoData.end_time | formatTime
            }}</span
          >
        </div>
      </div>
      <div class="info" style="margin-top: -10px">
        <div>
          购买限制：
          <span style="color: #000"
            >买家最多可以购买此套装优惠{{ infoData.buy_limit }}次</span
          >
        </div>
      </div>
    </div>
    <div class="cell2">
      <div class="titles">套装优惠商品</div>
      <div style="margin-top: 20px">
        <el-input
          size="small"
          style="width: 530px"
          v-model="keyword"
          class="input-with-select"
        >
          <el-select
            style="width: 180px"
            v-model="select"
            slot="prepend"
            placeholder="请选择"
          >
            <el-option label="商品名称" value="1"></el-option>
            <!-- <el-option label="商品ID" value="2"></el-option> -->
          </el-select>
          <el-button
            @click="goodsQuery"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </div>
      <div style="margin: 20px 0; font-size: 12px">
        总共{{ listData.list.length }}件商品
      </div>
      <div class="list">
        <div class="list-head">
          <div style="width: 50%">商品详情</div>
          <div style="width: 10%; display: flex; align-items: center">
            <span style="margin-right: 6px"> 价格</span>
            <!-- <div>
              <div class="triangleT"></div>
              <div class="triangleB"></div>
            </div> -->
          </div>

          <div style="width: 10%">库存</div>
          <div style="width: 15%">运送资料</div>
        </div>
        <div v-if="listData.list && listData.list.length != 0">
          <div
            class="list-content"
            v-for="item in listData.list"
            :key="item.id"
          >
            <div>
              <div class="one">
                <div style="width: 50%; display: flex">
                  <div>
                    <el-image
                      style="width: 60px; height: 60px; margin-right: 10px"
                      :src="item.goods_image"
                      fit="fit"
                    ></el-image>
                  </div>
                  <div>
                    <div
                      class="single-hidden"
                      style="font-size: 14px; width: 300px"
                    >
                      {{ item.goods_name }}
                    </div>
                    <div
                      style="font-size: 12px; margin-top: 4px; color: #b0b0b0"
                    >
                      全球商品货号：{{ item.goods_sn ? item.goods_sn : "--" }}
                    </div>
                  </div>
                </div>
                <div style="width: 10%">
                  <div>RM{{ item.goods_price }}</div>
                </div>
                <div style="width: 10%">{{ item.stock }}</div>
                <div style="width: 15%">{{ item.freight_name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          style="text-align: center; line-height: 90px; color: #b0b0b0"
        >
          未找到
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: right; margin-top: 30px">
      <el-button @click="toBack" size="small" style="min-width: 120px" plain
        >返回列表页</el-button
      >
    </div>
  </div>
</template>
<script>
import { getSuitInfo, suitDetails } from "@/api/marketing.js";
export default {
  data() {
    return {
      select: "1",
      keyword: "",
      infoData: "",
      listData: {
        list: [],
        lists: [],
      },
    };
  },
  created() {
    this.getInfo();
    this.getList();
  },
  methods: {
    getInfo() {
      getSuitInfo({
        suit_id: sessionStorage.SUITID,
      }).then((res) => {
        if (res.code == 1) {
          this.infoData = res.data;
        }
      });
    },
    getList() {
      suitDetails({
        id: sessionStorage.SUITID,
        goods_name: this.select == 1 ? this.keyword : "",
        goods_id: this.select == 2 ? this.keyword : "",
        page: 1,
        page_size: 10000,
      }).then((res) => {
        if (res.code == 1) {
          this.listData = res.data;
          this.listData.lists = res.data.list;
        }
      });
    },
    toBack() {
      this.$router.go(-1);
    },
    goodsQuery() {
      if (this.keyword != "") {
        let arr = [];
        this.listData.list.forEach((item) => {
          if (item.goods_name.indexOf(this.keyword) != -1) {
            arr.push(item);
          }
        });
        this.listData.list = arr;
      } else {
        this.listData.list = this.listData.lists;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.suitInfo {
  width: 1400px;
  margin: 0 auto;
  padding-top: 20px;
  .title {
    font-size: 20px;
    .tag1 {
      background: #eaf9ef;
      color: #55cc77;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
      font-size: 12px;
    }
    .tag {
      background: #fff1f0;
      color: #ee4d2d;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
      font-size: 12px;
    }
    .tag2 {
      background: #eeeeee;
      color: #666;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
      font-size: 12px;
    }
  }
  .cell1 {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    .titles {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .info {
      display: flex;
      justify-content: space-between;
      padding-top: 30px;
      font-size: 14px;
      color: #929495;
    }
  }
  .cell2 {
    padding: 20px;
    background: #fff;
    margin-top: 20px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    .titles {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .list {
      border: 1px solid #eee;
      .list-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        background: #f6f6f6;
        border-radius: 4px;
        padding: 10px 20px;
      }
      .list-content {
        font-size: 14px;
        .one {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 20px;
          background: #fcfcfc;
        }
      }
    }
  }
}
</style>